<template>
<div class="imgss_button" @click="this.$router.push({name:'home'})">
</div>
</template>
<style>
.imgss_button {
    background: url(../../assets/icons/404.jpg) no-repeat;
    background-size:100% 100%;  
    width: 100%;
    height: 100%;
}
</style>